<template>
  <div class="flex items-center justify-center flex-col p-32">
      <idc-logo width="400" height="50"/>
      <p class="text-3xl mt-4 text-gray-800 text-center">Trabajando en nuevas características</p>
  </div>
</template>

<script>
import IdcLogo from '@/components/ui/IDCLogo.vue';
export default {
  name: 'home',
  components: {
      IdcLogo,
  }
}
</script>
